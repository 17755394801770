@charset "UTF-8";
@import url(../../node_modules/vpro-bootstrap/src/fonts/simplistic_sans/font.css);
@import url(../../node_modules/vpro-bootstrap/src/fonts/vpro_vesta/font.css);
@import url(../../node_modules/vpro-bootstrap/src/fonts/vpro-iconfont/iconfont.css);
/**
 * This file acts as a template for a VPRO.nl like style.css. Override the values
 * of this file before including this one to configure your own version of the VPRO.nl
 * styles.
 * Be sure to check if those users of this template compile without errors!
 *
 * Current users of this template are:
 * Human: magnolia-module-vpro-site-human/src/main/resources/vpro/themes/human/css/sass
 * NPO Doc: magnolia-module-vpro-site-npodoc/src/main/resources/vpro/themes/npodoc/css/sass
 */
/* keep color names in strings, or production build will fail in the vpro-colored mixins */
/**
 * Last-index v1
 * More readable code
 * Slightly worse performance
 */
/**
* start general icon-font
*
*/
/**
* end general icon font
*
*/
/**
 * colorize a property of the target element for each possible color state
 *
 * $property - a CSS property, like color or background-color
 * $alpha - 0-1 alpha
 * $type - normal / light / dark - the vpro color to use
 */
/**
 * @param $include-default-idx; include index nr 1 as the default icon sprite
 */
/**
 * Component theming
 *
 * Elements that have a 'theme-{property}' class and are a descendant of a 'theme-{name}'
 * will get the property color of the named theme.
 *
 * Theme-{property} classes can be added to area's or a component's elements
 *
 * Theme-{name} classes are added to the page body or on components that can specify their
 * own background color. In the latter case, to force a component theme, the .component-theme
 * class should be added to the component's .theme-{name} element (which
 * by the way, will only work one level deep).
 *
 * In addition, a site can specify component background color (or background color defining) names in _config.scss that
 * will match a specific component theme when used as .component-theme.{color name}
 *
 * The theme classes provide hover themed properties which will be triggered on hover of the element.
 * You can use a special theme-hovered class on any element to trigger all descending
 * theme-{property}-hover classes when the theme-hovered element is hovered
 *
 *
 * Examples ( or http://codepen.io/frenkie/pen/vEQgPY ):
 *
 *  <body>.theme-white
 *
 *      <h1>.theme-title
            This component will receive the title color of the white theme
 *
 *      <component>.component-theme.theme-dark
 *
 *          <h1>.theme-title
 *               This component will receive the title color of the dark component theme
 *
 *      <component>.component-theme.bg-blue
 *
 *          <h1>.theme-title
 *              This component will get the title color of the theme that matches
 *              with the blue background color as specified in the _config.scss
 *              or the theme of a higher tag if the color doesn't
 *              match one itself, in this case the white theme of the <body> tag.
 *
 *      <component>.theme-text-hover
 *           This component will get the hover text color if it is hovered
 *
 *
 *      <component>.theme-hovered
 *
 *          <h1>.theme-title-hover
 *          <icon>.theme-icon-hover
 *               Both elements will get their hover color when they or it's parent
 *               are/is hovered
 *
 */
/**
 * Theme classes define which CSS properties can be themed by using the correct
 * property class on an element.
 *
 * The order of theme classes corresponds to the values from the configured theme lists in _config.scss
 * Leave a value blank (empty string) if you don't want to use it
 *
 * A site can force it's components to have primary colored headings or links.
 * Theme coloring of those properties won't be set then, but to maintain order,
 * arrays with 1 item will be added to the theme classes list.
 *
 * Forcing primary colors is only allowed in the white theme
 */
* {
  margin: 0;
  padding: 0;
  /* Border-box FTW! */
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  /* */
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

html {
  font-size: 100%;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%; }

img {
  border: 0; }

button {
  border: 0;
  border-radius: 0; }

li {
  list-style-type: none; }

fieldset {
  border: none; }

/*
 *
 * Grid layout.
 *
 */
.grid {
  max-width: 1072px;
  min-width: 320px;
  margin: 0 auto;
  display: block;
  clear: both; }
  .grid:before, .grid:after {
    content: " ";
    display: table; }
  .grid:after {
    clear: both; }

.grid-gutter {
  padding-left: 8px;
  padding-right: 8px; }

@media screen and (min-width: 768px) {
  .grid-gutter {
    padding-left: 12px;
    padding-right: 12px; } }

.col {
  width: 100%; }

@media screen and (min-width: 768px) {
  .col {
    float: left;
    min-height: 8px; }
    .col:before, .col:after {
      content: " ";
      display: table; }
    .col:after {
      clear: both; } }

.col.reverse {
  float: right; }

@media screen and (min-width: 768px) {
  .col-2-1 {
    width: 50.000%; }
  .col-3-1 {
    width: 33.333%; }
  .col-3-2 {
    width: 66.666%; }
  .col-4-1 {
    width: 25.000%; }
  .col-4-2 {
    width: 50.000%; }
  .col-4-3 {
    width: 75.000%; }
  .col-6-1 {
    width: 16.666%; }
  .col-6-2 {
    width: 33.333%; }
  .col-6-3 {
    width: 50.000%; }
  .col-6-4 {
    width: 66.666%; }
  .col-6-5 {
    width: 83.333%; }
  .col-8-1 {
    width: 12.500%; }
  .col-8-2 {
    width: 25.000%; }
  .col-8-3 {
    width: 37.500%; }
  .col-8-4 {
    width: 50.000%; }
  .col-8-5 {
    width: 62.500%; }
  .col-8-6 {
    width: 75.000%; }
  .col-8-7 {
    width: 87.500%; }
  .col-12-1 {
    width: 8.333%; }
  .col-12-2 {
    width: 16.666%; }
  .col-12-3 {
    width: 25.000%; }
  .col-12-4 {
    width: 33.333%; }
  .col-12-5 {
    width: 41.666%; }
  .col-12-6 {
    width: 50.000%; }
  .col-12-7 {
    width: 58.333%; }
  .col-12-8 {
    width: 66.666%; }
  .col-12-9 {
    width: 75.000%; }
  .col-12-10 {
    width: 83.333%; }
  .col-12-11 {
    width: 91.666%; } }

.col-gutter {
  margin-left: 8px;
  margin-right: 8px; }

.col-gutter.negative {
  margin-left: -8px;
  margin-right: -8px; }

@media screen and (min-width: 768px) {
  .col-gutter {
    margin-left: 12px;
    margin-right: 12px; }
  .col-gutter.negative {
    margin-left: -12px;
    margin-right: -12px; } }

/*
 *
 * Element layout.
 *
 */
.component {
  margin-left: 8px;
  margin-right: 8px;
  margin-bottom: 16px; }

@media screen and (min-width: 768px) {
  .component {
    margin-left: 12px;
    margin-right: 12px;
    margin-bottom: 24px; } }

.component-responsive {
  width: 100%; }

html, body {
  font-family: "vpro_vesta", Verdana, Arial, Helvetica, Sans, FreeSans, "Nimbus Sans L", Garuda, sans-serif;
  font-size: 15px;
  line-height: 24px;
  color: #333; }

body {
  background-color: #fff; }

h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6 {
  color: #0af;
  font-family: "simplistic_sans", "SimplisticSans", Arial, Helvetica, Sans, FreeSans, "Nimbus Sans L", Garuda, sans-serif; }

h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4 {
  font-weight: bold; }

h5, .h5,
h6, .h6 {
  font-weight: normal; }

h1, .h1 {
  font-size: 40px;
  line-height: 40px; }

h2, .h2 {
  font-size: 32px;
  line-height: 32px; }

h3, .h3 {
  font-size: 28px;
  line-height: 28px; }

h4, .h4 {
  font-size: 24px;
  line-height: 24px; }

h5, .h5 {
  font-size: 20px;
  line-height: 20px; }

h6, .h6 {
  font-size: 16px;
  line-height: 16px; }

@media screen and (min-width: 768px) {
  h1, .h1 {
    font-size: 64px;
    line-height: 64px; }
  h2, .h2 {
    font-size: 48px;
    line-height: 48px; }
  h3, .h3 {
    font-size: 40px;
    line-height: 40px; }
  h4, .h4 {
    font-size: 32px;
    line-height: 32px; }
  h5, .h5 {
    font-size: 24px;
    line-height: 24px; }
  h6, .h6 {
    font-size: 16px;
    line-height: 16px; } }

/* TODO: these margins should be removed, but that will probably cause a lot of missing margins... */
p {
  margin: 0 0 16px; }

@media screen and (min-width: 768px) {
  p {
    margin: 0 0 24px; } }

em, i {
  font-style: italic; }

strong, b {
  font-weight: bold; }

a {
  color: #000;
  text-decoration: none; }

a:hover {
  text-decoration: underline; }

.clearfix:before, .clearfix:after {
  content: " ";
  display: table; }

.clearfix:after {
  clear: both; }

/* @start icon */
.icon, .glyph {
  font-family: 'vpro-iconfont';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 30px;
  overflow: hidden;
  display: block;
  color: #0af;
  font-size: 30px;
  line-height: 0.85;
  text-align: center;
  width: 24px;
  height: 24px; }

/* @end icon */
/* @start iconfont */
.iconfont {
  font-family: 'vpro-iconfont';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

/* @end iconfont */
.darken {
  display: none; }

.player-darken {
  background-color: #000;
  opacity: 0.3; }

.data {
  font-size: 13px;
  line-height: 13px;
  font-family: "vpro_vesta_big", Verdana, Arial, Helvetica, Sans, FreeSans, "Nimbus Sans L", Garuda, sans-serif;
  text-transform: lowercase;
  color: #666;
  font-weight: normal;
  line-height: 16px; }

.overlay {
  background: rgba(0, 0, 0, 0.7);
  position: fixed;
  display: block;
  bottom: 0px;
  right: 0px;
  left: 0px;
  top: 0px;
  z-index: 500; }

.bg-white {
  background-color: #fff; }

.bg-black {
  background-color: #000; }

.bg-blue {
  background-color: #0af; }

.bg-gold {
  background-color: #f90; }

.bg-green {
  background-color: #0c0; }

.bg-greybat {
  background-color: #333; }

.bg-greyelephant {
  background-color: #aaa; }

.bg-greygrizzly {
  background-color: #666; }

.bg-greymouse {
  background-color: #ccc; }

.bg-greyjerboa {
  background-color: #eee; }

.bg-greywolf {
  background-color: #d6d6d6; }

.bg-lime {
  background-color: #0f0; }

.bg-orange {
  background-color: #f50; }

.bg-pink {
  background-color: #f09; }

.bg-plum {
  background-color: #c0f; }

.bg-red {
  background-color: #f00; }

.bg-royal {
  background-color: #06f; }

.bg-turquoise {
  background-color: #0cc; }

.bg-violet {
  background-color: #66f; }

.bg-yellow {
  background-color: #ff0; }

.bg-lightblue {
  background-color: #0cf; }

.bg-lightgold {
  background-color: #fc3; }

.bg-lightgreen {
  background-color: #3f9; }

.bg-lightlime {
  background-color: #9f0; }

.bg-lightorange {
  background-color: #f93; }

.bg-lightpink {
  background-color: #f9c; }

.bg-lightplum {
  background-color: #c9f; }

.bg-lightred {
  background-color: #f99; }

.bg-lightroyal {
  background-color: #69f; }

.bg-lightturquoise {
  background-color: #0ff; }

.bg-lightviolet {
  background-color: #99f; }

.bg-lightyellow {
  background-color: #ff6; }

.bg-darkblue {
  background-color: #00f; }

.bg-darkgold {
  background-color: #c90; }

.bg-darkgreen {
  background-color: #090; }

.bg-darklime {
  background-color: #0c0; }

.bg-darkorange {
  background-color: #f30; }

.bg-darkpink {
  background-color: #c36; }

.bg-darkplum {
  background-color: #93c; }

.bg-darkred {
  background-color: #c00; }

.bg-darkroyal {
  background-color: #03c; }

.bg-darkturquoise {
  background-color: #099; }

.bg-darkviolet {
  background-color: #93a; }

.bg-darkyellow {
  background-color: #fd0; }

.bg-darkgreywolf {
  background-color: #ccc; }

.bg-darkgreymouse {
  background-color: #aaa; }

.bg-darkgreyelephant {
  background-color: #666; }

.bg-darkgreygrizzly {
  background-color: #333; }

.bg-darkgreybat {
  background-color: #000; }

.bg-hover-white:hover {
  background-color: #fff; }

.bg-hover-black:hover {
  background-color: #000; }

.bg-hover-blue:hover {
  background-color: #0af; }

.bg-hover-gold:hover {
  background-color: #f90; }

.bg-hover-green:hover {
  background-color: #0c0; }

.bg-hover-greybat:hover {
  background-color: #333; }

.bg-hover-greyelephant:hover {
  background-color: #aaa; }

.bg-hover-greygrizzly:hover {
  background-color: #666; }

.bg-hover-greymouse:hover {
  background-color: #ccc; }

.bg-hover-greyjerboa:hover {
  background-color: #eee; }

.bg-hover-greywolf:hover {
  background-color: #d6d6d6; }

.bg-hover-lime:hover {
  background-color: #0f0; }

.bg-hover-orange:hover {
  background-color: #f50; }

.bg-hover-pink:hover {
  background-color: #f09; }

.bg-hover-plum:hover {
  background-color: #c0f; }

.bg-hover-red:hover {
  background-color: #f00; }

.bg-hover-royal:hover {
  background-color: #06f; }

.bg-hover-turquoise:hover {
  background-color: #0cc; }

.bg-hover-violet:hover {
  background-color: #66f; }

.bg-hover-yellow:hover {
  background-color: #ff0; }

.bg-hover-lightblue:hover {
  background-color: #0cf; }

.bg-hover-lightgold:hover {
  background-color: #fc3; }

.bg-hover-lightgreen:hover {
  background-color: #3f9; }

.bg-hover-lightlime:hover {
  background-color: #9f0; }

.bg-hover-lightorange:hover {
  background-color: #f93; }

.bg-hover-lightpink:hover {
  background-color: #f9c; }

.bg-hover-lightplum:hover {
  background-color: #c9f; }

.bg-hover-lightred:hover {
  background-color: #f99; }

.bg-hover-lightroyal:hover {
  background-color: #69f; }

.bg-hover-lightturquoise:hover {
  background-color: #0ff; }

.bg-hover-lightviolet:hover {
  background-color: #99f; }

.bg-hover-lightyellow:hover {
  background-color: #ff6; }

.bg-hover-darkblue:hover {
  background-color: #00f; }

.bg-hover-darkgold:hover {
  background-color: #c90; }

.bg-hover-darkgreen:hover {
  background-color: #090; }

.bg-hover-darklime:hover {
  background-color: #0c0; }

.bg-hover-darkorange:hover {
  background-color: #f30; }

.bg-hover-darkpink:hover {
  background-color: #c36; }

.bg-hover-darkplum:hover {
  background-color: #93c; }

.bg-hover-darkred:hover {
  background-color: #c00; }

.bg-hover-darkroyal:hover {
  background-color: #03c; }

.bg-hover-darkturquoise:hover {
  background-color: #099; }

.bg-hover-darkviolet:hover {
  background-color: #93a; }

.bg-hover-darkyellow:hover {
  background-color: #fd0; }

.bg-hover-darkgreywolf:hover {
  background-color: #ccc; }

.bg-hover-darkgreymouse:hover {
  background-color: #aaa; }

.bg-hover-darkgreyelephant:hover {
  background-color: #666; }

.bg-hover-darkgreygrizzly:hover {
  background-color: #333; }

.bg-hover-darkgreybat:hover {
  background-color: #000; }

.bg-white {
  background-color: #fff; }

/**
 *
 */
.c-white {
  color: #fff; }

.c-black {
  color: #000; }

.c-blue {
  color: #0af; }

.c-gold {
  color: #f90; }

.c-green {
  color: #0c0; }

.c-greybat {
  color: #333; }

.c-greyelephant {
  color: #aaa; }

.c-greygrizzly {
  color: #666; }

.c-greymouse {
  color: #ccc; }

.c-greyjerboa {
  color: #eee; }

.c-greywolf {
  color: #d6d6d6; }

.c-lime {
  color: #0f0; }

.c-orange {
  color: #f50; }

.c-pink {
  color: #f09; }

.c-plum {
  color: #c0f; }

.c-red {
  color: #f00; }

.c-royal {
  color: #06f; }

.c-turquoise {
  color: #0cc; }

.c-violet {
  color: #66f; }

.c-yellow {
  color: #ff0; }

.c-hover-white:hover {
  color: #fff; }

.c-hover-black:hover {
  color: #000; }

.c-hover-blue:hover {
  color: #0af; }

.c-hover-gold:hover {
  color: #f90; }

.c-hover-green:hover {
  color: #0c0; }

.c-hover-greybat:hover {
  color: #333; }

.c-hover-greyelephant:hover {
  color: #aaa; }

.c-hover-greygrizzly:hover {
  color: #666; }

.c-hover-greymouse:hover {
  color: #ccc; }

.c-hover-greyjerboa:hover {
  color: #eee; }

.c-hover-greywolf:hover {
  color: #d6d6d6; }

.c-hover-lime:hover {
  color: #0f0; }

.c-hover-orange:hover {
  color: #f50; }

.c-hover-pink:hover {
  color: #f09; }

.c-hover-plum:hover {
  color: #c0f; }

.c-hover-red:hover {
  color: #f00; }

.c-hover-royal:hover {
  color: #06f; }

.c-hover-turquoise:hover {
  color: #0cc; }

.c-hover-violet:hover {
  color: #66f; }

.c-hover-yellow:hover {
  color: #ff0; }

.c-darkblue {
  color: #00f; }

.c-darkgold {
  color: #c90; }

.c-darkgreen {
  color: #090; }

.c-darklime {
  color: #0c0; }

.c-darkorange {
  color: #f30; }

.c-darkpink {
  color: #c36; }

.c-darkplum {
  color: #93c; }

.c-darkred {
  color: #c00; }

.c-darkroyal {
  color: #03c; }

.c-darkturquoise {
  color: #099; }

.c-darkviolet {
  color: #93a; }

.c-darkyellow {
  color: #fd0; }

.c-darkgreywolf {
  color: #ccc; }

.c-darkgreymouse {
  color: #aaa; }

.c-darkgreyelephant {
  color: #666; }

.c-darkgreygrizzly {
  color: #333; }

.c-darkgreybat {
  color: #000; }

.c-lightblue {
  color: #0cf; }

.c-lightgold {
  color: #fc3; }

.c-lightgreen {
  color: #3f9; }

.c-lightlime {
  color: #9f0; }

.c-lightorange {
  color: #f93; }

.c-lightpink {
  color: #f9c; }

.c-lightplum {
  color: #c9f; }

.c-lightred {
  color: #f99; }

.c-lightroyal {
  color: #69f; }

.c-lightturquoise {
  color: #0ff; }

.c-lightviolet {
  color: #99f; }

.c-lightyellow {
  color: #ff6; }

.c-white {
  color: #fff; }

/*
 *
 * An alternative to using colored-property on too many items
 *
 */
.primary-white .primary-color {
  color: white; }

.primary-black .primary-color {
  color: black; }

.primary-blue .primary-color {
  color: #00aaff; }

.primary-gold .primary-color {
  color: #ff9900; }

.primary-green .primary-color {
  color: #00cc00; }

.primary-greybat .primary-color {
  color: #333333; }

.primary-greyelephant .primary-color {
  color: #aaaaaa; }

.primary-greygrizzly .primary-color {
  color: #666666; }

.primary-greymouse .primary-color {
  color: #cccccc; }

.primary-greyjerboa .primary-color {
  color: #eeeeee; }

.primary-greywolf .primary-color {
  color: #d6d6d6; }

.primary-lime .primary-color {
  color: lime; }

.primary-orange .primary-color {
  color: #ff5500; }

.primary-pink .primary-color {
  color: #ff0099; }

.primary-plum .primary-color {
  color: #cc00ff; }

.primary-red .primary-color {
  color: red; }

.primary-royal .primary-color {
  color: #0066ff; }

.primary-turquoise .primary-color {
  color: #00cccc; }

.primary-violet .primary-color {
  color: #6666ff; }

.primary-yellow .primary-color {
  color: yellow; }

.primary-white .primary-background-color, .primary-white .primary-background-color-hover:hover {
  background-color: white; }

.primary-black .primary-background-color, .primary-black .primary-background-color-hover:hover {
  background-color: black; }

.primary-blue .primary-background-color, .primary-blue .primary-background-color-hover:hover {
  background-color: #00aaff; }

.primary-gold .primary-background-color, .primary-gold .primary-background-color-hover:hover {
  background-color: #ff9900; }

.primary-green .primary-background-color, .primary-green .primary-background-color-hover:hover {
  background-color: #00cc00; }

.primary-greybat .primary-background-color, .primary-greybat .primary-background-color-hover:hover {
  background-color: #333333; }

.primary-greyelephant .primary-background-color, .primary-greyelephant .primary-background-color-hover:hover {
  background-color: #aaaaaa; }

.primary-greygrizzly .primary-background-color, .primary-greygrizzly .primary-background-color-hover:hover {
  background-color: #666666; }

.primary-greymouse .primary-background-color, .primary-greymouse .primary-background-color-hover:hover {
  background-color: #cccccc; }

.primary-greyjerboa .primary-background-color, .primary-greyjerboa .primary-background-color-hover:hover {
  background-color: #eeeeee; }

.primary-greywolf .primary-background-color, .primary-greywolf .primary-background-color-hover:hover {
  background-color: #d6d6d6; }

.primary-lime .primary-background-color, .primary-lime .primary-background-color-hover:hover {
  background-color: lime; }

.primary-orange .primary-background-color, .primary-orange .primary-background-color-hover:hover {
  background-color: #ff5500; }

.primary-pink .primary-background-color, .primary-pink .primary-background-color-hover:hover {
  background-color: #ff0099; }

.primary-plum .primary-background-color, .primary-plum .primary-background-color-hover:hover {
  background-color: #cc00ff; }

.primary-red .primary-background-color, .primary-red .primary-background-color-hover:hover {
  background-color: red; }

.primary-royal .primary-background-color, .primary-royal .primary-background-color-hover:hover {
  background-color: #0066ff; }

.primary-turquoise .primary-background-color, .primary-turquoise .primary-background-color-hover:hover {
  background-color: #00cccc; }

.primary-violet .primary-background-color, .primary-violet .primary-background-color-hover:hover {
  background-color: #6666ff; }

.primary-yellow .primary-background-color, .primary-yellow .primary-background-color-hover:hover {
  background-color: yellow; }

.primary-white .primary-border-color, .primary-white .primary-border-color-hover:hover {
  border-color: white; }

.primary-black .primary-border-color, .primary-black .primary-border-color-hover:hover {
  border-color: black; }

.primary-blue .primary-border-color, .primary-blue .primary-border-color-hover:hover {
  border-color: #00aaff; }

.primary-gold .primary-border-color, .primary-gold .primary-border-color-hover:hover {
  border-color: #ff9900; }

.primary-green .primary-border-color, .primary-green .primary-border-color-hover:hover {
  border-color: #00cc00; }

.primary-greybat .primary-border-color, .primary-greybat .primary-border-color-hover:hover {
  border-color: #333333; }

.primary-greyelephant .primary-border-color, .primary-greyelephant .primary-border-color-hover:hover {
  border-color: #aaaaaa; }

.primary-greygrizzly .primary-border-color, .primary-greygrizzly .primary-border-color-hover:hover {
  border-color: #666666; }

.primary-greymouse .primary-border-color, .primary-greymouse .primary-border-color-hover:hover {
  border-color: #cccccc; }

.primary-greyjerboa .primary-border-color, .primary-greyjerboa .primary-border-color-hover:hover {
  border-color: #eeeeee; }

.primary-greywolf .primary-border-color, .primary-greywolf .primary-border-color-hover:hover {
  border-color: #d6d6d6; }

.primary-lime .primary-border-color, .primary-lime .primary-border-color-hover:hover {
  border-color: lime; }

.primary-orange .primary-border-color, .primary-orange .primary-border-color-hover:hover {
  border-color: #ff5500; }

.primary-pink .primary-border-color, .primary-pink .primary-border-color-hover:hover {
  border-color: #ff0099; }

.primary-plum .primary-border-color, .primary-plum .primary-border-color-hover:hover {
  border-color: #cc00ff; }

.primary-red .primary-border-color, .primary-red .primary-border-color-hover:hover {
  border-color: red; }

.primary-royal .primary-border-color, .primary-royal .primary-border-color-hover:hover {
  border-color: #0066ff; }

.primary-turquoise .primary-border-color, .primary-turquoise .primary-border-color-hover:hover {
  border-color: #00cccc; }

.primary-violet .primary-border-color, .primary-violet .primary-border-color-hover:hover {
  border-color: #6666ff; }

.primary-yellow .primary-border-color, .primary-yellow .primary-border-color-hover:hover {
  border-color: yellow; }

.primary-white .primary-background-30-color, .primary-white .primary-background-30-color-hover:hover {
  background-color: rgba(255, 255, 255, 0.3); }

.primary-black .primary-background-30-color, .primary-black .primary-background-30-color-hover:hover {
  background-color: rgba(0, 0, 0, 0.3); }

.primary-blue .primary-background-30-color, .primary-blue .primary-background-30-color-hover:hover {
  background-color: rgba(0, 170, 255, 0.3); }

.primary-gold .primary-background-30-color, .primary-gold .primary-background-30-color-hover:hover {
  background-color: rgba(255, 153, 0, 0.3); }

.primary-green .primary-background-30-color, .primary-green .primary-background-30-color-hover:hover {
  background-color: rgba(0, 204, 0, 0.3); }

.primary-greybat .primary-background-30-color, .primary-greybat .primary-background-30-color-hover:hover {
  background-color: rgba(51, 51, 51, 0.3); }

.primary-greyelephant .primary-background-30-color, .primary-greyelephant .primary-background-30-color-hover:hover {
  background-color: rgba(170, 170, 170, 0.3); }

.primary-greygrizzly .primary-background-30-color, .primary-greygrizzly .primary-background-30-color-hover:hover {
  background-color: rgba(102, 102, 102, 0.3); }

.primary-greymouse .primary-background-30-color, .primary-greymouse .primary-background-30-color-hover:hover {
  background-color: rgba(204, 204, 204, 0.3); }

.primary-greyjerboa .primary-background-30-color, .primary-greyjerboa .primary-background-30-color-hover:hover {
  background-color: rgba(238, 238, 238, 0.3); }

.primary-greywolf .primary-background-30-color, .primary-greywolf .primary-background-30-color-hover:hover {
  background-color: rgba(214, 214, 214, 0.3); }

.primary-lime .primary-background-30-color, .primary-lime .primary-background-30-color-hover:hover {
  background-color: rgba(0, 255, 0, 0.3); }

.primary-orange .primary-background-30-color, .primary-orange .primary-background-30-color-hover:hover {
  background-color: rgba(255, 85, 0, 0.3); }

.primary-pink .primary-background-30-color, .primary-pink .primary-background-30-color-hover:hover {
  background-color: rgba(255, 0, 153, 0.3); }

.primary-plum .primary-background-30-color, .primary-plum .primary-background-30-color-hover:hover {
  background-color: rgba(204, 0, 255, 0.3); }

.primary-red .primary-background-30-color, .primary-red .primary-background-30-color-hover:hover {
  background-color: rgba(255, 0, 0, 0.3); }

.primary-royal .primary-background-30-color, .primary-royal .primary-background-30-color-hover:hover {
  background-color: rgba(0, 102, 255, 0.3); }

.primary-turquoise .primary-background-30-color, .primary-turquoise .primary-background-30-color-hover:hover {
  background-color: rgba(0, 204, 204, 0.3); }

.primary-violet .primary-background-30-color, .primary-violet .primary-background-30-color-hover:hover {
  background-color: rgba(102, 102, 255, 0.3); }

.primary-yellow .primary-background-30-color, .primary-yellow .primary-background-30-color-hover:hover {
  background-color: rgba(255, 255, 0, 0.3); }

.primary-white .primary-background-50-color, .primary-white .primary-background-50-color-hover:hover {
  background-color: rgba(255, 255, 255, 0.5); }

.primary-black .primary-background-50-color, .primary-black .primary-background-50-color-hover:hover {
  background-color: rgba(0, 0, 0, 0.5); }

.primary-blue .primary-background-50-color, .primary-blue .primary-background-50-color-hover:hover {
  background-color: rgba(0, 170, 255, 0.5); }

.primary-gold .primary-background-50-color, .primary-gold .primary-background-50-color-hover:hover {
  background-color: rgba(255, 153, 0, 0.5); }

.primary-green .primary-background-50-color, .primary-green .primary-background-50-color-hover:hover {
  background-color: rgba(0, 204, 0, 0.5); }

.primary-greybat .primary-background-50-color, .primary-greybat .primary-background-50-color-hover:hover {
  background-color: rgba(51, 51, 51, 0.5); }

.primary-greyelephant .primary-background-50-color, .primary-greyelephant .primary-background-50-color-hover:hover {
  background-color: rgba(170, 170, 170, 0.5); }

.primary-greygrizzly .primary-background-50-color, .primary-greygrizzly .primary-background-50-color-hover:hover {
  background-color: rgba(102, 102, 102, 0.5); }

.primary-greymouse .primary-background-50-color, .primary-greymouse .primary-background-50-color-hover:hover {
  background-color: rgba(204, 204, 204, 0.5); }

.primary-greyjerboa .primary-background-50-color, .primary-greyjerboa .primary-background-50-color-hover:hover {
  background-color: rgba(238, 238, 238, 0.5); }

.primary-greywolf .primary-background-50-color, .primary-greywolf .primary-background-50-color-hover:hover {
  background-color: rgba(214, 214, 214, 0.5); }

.primary-lime .primary-background-50-color, .primary-lime .primary-background-50-color-hover:hover {
  background-color: rgba(0, 255, 0, 0.5); }

.primary-orange .primary-background-50-color, .primary-orange .primary-background-50-color-hover:hover {
  background-color: rgba(255, 85, 0, 0.5); }

.primary-pink .primary-background-50-color, .primary-pink .primary-background-50-color-hover:hover {
  background-color: rgba(255, 0, 153, 0.5); }

.primary-plum .primary-background-50-color, .primary-plum .primary-background-50-color-hover:hover {
  background-color: rgba(204, 0, 255, 0.5); }

.primary-red .primary-background-50-color, .primary-red .primary-background-50-color-hover:hover {
  background-color: rgba(255, 0, 0, 0.5); }

.primary-royal .primary-background-50-color, .primary-royal .primary-background-50-color-hover:hover {
  background-color: rgba(0, 102, 255, 0.5); }

.primary-turquoise .primary-background-50-color, .primary-turquoise .primary-background-50-color-hover:hover {
  background-color: rgba(0, 204, 204, 0.5); }

.primary-violet .primary-background-50-color, .primary-violet .primary-background-50-color-hover:hover {
  background-color: rgba(102, 102, 255, 0.5); }

.primary-yellow .primary-background-50-color, .primary-yellow .primary-background-50-color-hover:hover {
  background-color: rgba(255, 255, 0, 0.5); }

.primary-white .primary-background-80-color, .primary-white .primary-background-80-color-hover:hover {
  background-color: rgba(255, 255, 255, 0.8); }

.primary-black .primary-background-80-color, .primary-black .primary-background-80-color-hover:hover {
  background-color: rgba(0, 0, 0, 0.8); }

.primary-blue .primary-background-80-color, .primary-blue .primary-background-80-color-hover:hover {
  background-color: rgba(0, 170, 255, 0.8); }

.primary-gold .primary-background-80-color, .primary-gold .primary-background-80-color-hover:hover {
  background-color: rgba(255, 153, 0, 0.8); }

.primary-green .primary-background-80-color, .primary-green .primary-background-80-color-hover:hover {
  background-color: rgba(0, 204, 0, 0.8); }

.primary-greybat .primary-background-80-color, .primary-greybat .primary-background-80-color-hover:hover {
  background-color: rgba(51, 51, 51, 0.8); }

.primary-greyelephant .primary-background-80-color, .primary-greyelephant .primary-background-80-color-hover:hover {
  background-color: rgba(170, 170, 170, 0.8); }

.primary-greygrizzly .primary-background-80-color, .primary-greygrizzly .primary-background-80-color-hover:hover {
  background-color: rgba(102, 102, 102, 0.8); }

.primary-greymouse .primary-background-80-color, .primary-greymouse .primary-background-80-color-hover:hover {
  background-color: rgba(204, 204, 204, 0.8); }

.primary-greyjerboa .primary-background-80-color, .primary-greyjerboa .primary-background-80-color-hover:hover {
  background-color: rgba(238, 238, 238, 0.8); }

.primary-greywolf .primary-background-80-color, .primary-greywolf .primary-background-80-color-hover:hover {
  background-color: rgba(214, 214, 214, 0.8); }

.primary-lime .primary-background-80-color, .primary-lime .primary-background-80-color-hover:hover {
  background-color: rgba(0, 255, 0, 0.8); }

.primary-orange .primary-background-80-color, .primary-orange .primary-background-80-color-hover:hover {
  background-color: rgba(255, 85, 0, 0.8); }

.primary-pink .primary-background-80-color, .primary-pink .primary-background-80-color-hover:hover {
  background-color: rgba(255, 0, 153, 0.8); }

.primary-plum .primary-background-80-color, .primary-plum .primary-background-80-color-hover:hover {
  background-color: rgba(204, 0, 255, 0.8); }

.primary-red .primary-background-80-color, .primary-red .primary-background-80-color-hover:hover {
  background-color: rgba(255, 0, 0, 0.8); }

.primary-royal .primary-background-80-color, .primary-royal .primary-background-80-color-hover:hover {
  background-color: rgba(0, 102, 255, 0.8); }

.primary-turquoise .primary-background-80-color, .primary-turquoise .primary-background-80-color-hover:hover {
  background-color: rgba(0, 204, 204, 0.8); }

.primary-violet .primary-background-80-color, .primary-violet .primary-background-80-color-hover:hover {
  background-color: rgba(102, 102, 255, 0.8); }

.primary-yellow .primary-background-80-color, .primary-yellow .primary-background-80-color-hover:hover {
  background-color: rgba(255, 255, 0, 0.8); }

.logo-white .logo-background-color, .logo-white .logo-background-color-hover:hover {
  background-color: white; }

.logo-black .logo-background-color, .logo-black .logo-background-color-hover:hover {
  background-color: black; }

.logo-blue .logo-background-color, .logo-blue .logo-background-color-hover:hover {
  background-color: #00aaff; }

.logo-gold .logo-background-color, .logo-gold .logo-background-color-hover:hover {
  background-color: #ff9900; }

.logo-green .logo-background-color, .logo-green .logo-background-color-hover:hover {
  background-color: #00cc00; }

.logo-greybat .logo-background-color, .logo-greybat .logo-background-color-hover:hover {
  background-color: #333333; }

.logo-greyelephant .logo-background-color, .logo-greyelephant .logo-background-color-hover:hover {
  background-color: #aaaaaa; }

.logo-greygrizzly .logo-background-color, .logo-greygrizzly .logo-background-color-hover:hover {
  background-color: #666666; }

.logo-greymouse .logo-background-color, .logo-greymouse .logo-background-color-hover:hover {
  background-color: #cccccc; }

.logo-greyjerboa .logo-background-color, .logo-greyjerboa .logo-background-color-hover:hover {
  background-color: #eeeeee; }

.logo-greywolf .logo-background-color, .logo-greywolf .logo-background-color-hover:hover {
  background-color: #d6d6d6; }

.logo-lime .logo-background-color, .logo-lime .logo-background-color-hover:hover {
  background-color: lime; }

.logo-orange .logo-background-color, .logo-orange .logo-background-color-hover:hover {
  background-color: #ff5500; }

.logo-pink .logo-background-color, .logo-pink .logo-background-color-hover:hover {
  background-color: #ff0099; }

.logo-plum .logo-background-color, .logo-plum .logo-background-color-hover:hover {
  background-color: #cc00ff; }

.logo-red .logo-background-color, .logo-red .logo-background-color-hover:hover {
  background-color: red; }

.logo-royal .logo-background-color, .logo-royal .logo-background-color-hover:hover {
  background-color: #0066ff; }

.logo-turquoise .logo-background-color, .logo-turquoise .logo-background-color-hover:hover {
  background-color: #00cccc; }

.logo-violet .logo-background-color, .logo-violet .logo-background-color-hover:hover {
  background-color: #6666ff; }

.logo-yellow .logo-background-color, .logo-yellow .logo-background-color-hover:hover {
  background-color: yellow; }

.glyph-centered {
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -12px;
  margin-top: -12px;
  height: 24px;
  width: 24px; }

.glyph-size-small {
  font-size: 20px; }
  .glyph-size-small.glyph-centered {
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: -8px;
    margin-top: -8px;
    height: 16px;
    width: 16px; }

.glyph-size-large {
  font-size: 54px; }
  .glyph-size-large.glyph-centered {
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: -24px;
    margin-top: -24px;
    height: 48px;
    width: 48px; }

.glyph-pause:before {
  content: "p"; }

.glyph-play:before {
  content: "q"; }

.glyph-listen:before {
  content: "l"; }

.glyph-mute:before {
  content: "m"; }

.glyph-next:before {
  content: "n"; }

.glyph-close:before {
  content: "c"; }

.glyph-fullscreen:before {
  content: "f"; }

.glyph-embed:before {
  content: "e"; }

.glyph-info:before {
  content: "i"; }

.glyph-react:before {
  content: "s"; }

.glyph-scrubber:before {
  content: "t"; }

.glyph-search:before {
  content: "u"; }

.glyph-cookies:before {
  content: "d"; }

.glyph-download:before {
  content: "g"; }

.glyph-arrowleft:before {
  content: "a"; }

.glyph-arrowright:before {
  content: "b"; }

.glyph-navigation:before {
  content: "r"; }

.glyph-exitfullscreen:before {
  content: "h"; }

.glyph-calendar:before {
  content: "j"; }

.glyph-shop:before {
  content: "v"; }

.glyph-share:before {
  content: "w"; }

.glyph-favorite:before {
  content: "k"; }

.glyph-alert:before {
  content: "z"; }

.glyph-pulldown:before {
  content: "{"; }

.glyph-afterten:before {
  content: "|"; }

.glyph-check:before {
  content: "M"; }

.glyph-vpro:before {
  content: "V"; }

.glyph-flags:before {
  content: "W"; }

.glyph-dots:before {
  content: "X"; }

.glyph-vprooutline:before {
  content: "Y"; }

.glyph-alleleeftijden:before {
  content: "A"; }

.glyph-6jaar:before {
  content: "B"; }

.glyph-9jaar:before {
  content: "C"; }

.glyph-12jaar:before {
  content: "D"; }

.glyph-16jaar:before {
  content: "E"; }

.glyph-geweld:before {
  content: "J"; }

.glyph-angst:before {
  content: "I"; }

.glyph-seks:before {
  content: "H"; }

.glyph-discriminatie:before {
  content: "G"; }

.glyph-drugs:before {
  content: "F"; }

.glyph-groftaal:before {
  content: "K"; }

.glyph-popout:before {
  content: "x"; }

.glyph-pausemall:before {
  content: "L"; }

.glyph-alertmedium:before {
  content: "}"; }

.glyph-aftertenmedium:before {
  content: "y"; }

.glyph-alertsmall:before {
  content: "N"; }

.glyph-aftertensmall:before {
  content: ""; }

.glyph-externallink:before {
  content: "Q"; }

.glyph-up:before {
  content: "~"; }

.glyph-previous:before {
  content: "1"; }

.glyph-down:before {
  content: "Z"; }

.glyph-collectie:before {
  content: "U"; }

.glyph-showgrid:before {
  content: "P"; }

.glyph-showlist:before {
  content: "R"; }

.glyph-watchlater:before {
  content: "T"; }

.glyph-heart:before {
  content: "S"; }

.glyph-showexpanded:before {
  content: "]"; }

.glyph-play-circle:before {
  content: "@"; }

.glyph-halfstar:before {
  content: "*"; }

.glyph-collapsed:before {
  content: "o"; }

.glyph-person:before {
  content: ""; }

.glyph-clapboard:before {
  content: ""; }

.glyph-subtitles-active:before {
  content: ""; }

.glyph-subtitles-inactive:before {
  content: ""; }

.glyph-edit:before {
  content: ""; }

.glyph-trash:before {
  content: ""; }

.glyph-headphone:before {
  content: ""; }

.glyph-read:before {
  content: ""; }

.glyph-view:before {
  content: ""; }

.glyph-facebook:before {
  content: ""; }

.glyph-twitter:before {
  content: ""; }

.glyph-youtube:before {
  content: ""; }

.glyph-soundcloud:before {
  content: ""; }

.glyph-spotify:before {
  content: ""; }

.glyph-instagram:before {
  content: ""; }

.glyph-radio:before {
  content: ""; }

.glyph-tv:before {
  content: ""; }

.glyph-bandcamp:before {
  content: ""; }

.glyph-cc-by:before {
  content: ""; }

.glyph-cc-cc:before {
  content: ""; }

.glyph-cc-nc:before {
  content: ""; }

.glyph-cc-nd:before {
  content: ""; }

.glyph-cc-pd:before {
  content: ""; }

.glyph-cc-sa:before {
  content: ""; }

.glyph-color-white {
  color: #fff; }

.glyph-color-black {
  color: #000; }

.glyph-color-blue {
  color: #0af; }

.glyph-color-gold {
  color: #f90; }

.glyph-color-green {
  color: #0c0; }

.glyph-color-greybat {
  color: #333; }

.glyph-color-greyelephant {
  color: #aaa; }

.glyph-color-greygrizzly {
  color: #666; }

.glyph-color-greymouse {
  color: #ccc; }

.glyph-color-greyjerboa {
  color: #eee; }

.glyph-color-greywolf {
  color: #d6d6d6; }

.glyph-color-lime {
  color: #0f0; }

.glyph-color-orange {
  color: #f50; }

.glyph-color-pink {
  color: #f09; }

.glyph-color-plum {
  color: #c0f; }

.glyph-color-red {
  color: #f00; }

.glyph-color-royal {
  color: #06f; }

.glyph-color-turquoise {
  color: #0cc; }

.glyph-color-violet {
  color: #66f; }

.glyph-color-yellow {
  color: #ff0; }

.app-container {
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: black; }

.app {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: calc(100% - 40px);
  top: 40px;
  color: white;
  background-color: black;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  overflow: hidden; }

@media screen and (min-width: 768px) {
  .app {
    top: 48px;
    height: calc(100% - 48px); } }

.loader {
  width: 52px;
  opacity: .3; }

@media screen and (min-width: 768px) {
  .app {
    flex-direction: row; } }

a {
  color: white; }
  a:hover {
    text-decoration: none !important; }

#navbar-container {
  z-index: 99999998 !important; }

.navbar-header-hamburger {
  left: 0; }

.nav-a-z-grid .grid {
  min-width: 100%; }

.navigation .navbar-toplevel-search {
  display: none; }

audio {
  display: none; }

.mobile {
  display: inline-block; }

.desktop {
  display: none; }

.small {
  font-size: 12px;
  line-height: 15px; }

@media screen and (min-width: 768px) {
  .mobile {
    display: none; }
  .desktop {
    display: inline-block; } }

.background-01 {
  background-image: url("/assets/img/background-01.jpg"); }

.background-02 {
  background-image: url("/assets/img/background-02.jpg"); }

.background-03 {
  background-image: url("/assets/img/background-03.jpg"); }

.background-04 {
  background-image: url("/assets/img/background-04.jpg"); }

.background-05 {
  background-image: url("/assets/img/background-05.jpg"); }

.detail-container {
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  transform: translateY(0);
  transition: all 1000ms cubic-bezier(0, 1.005, 0.22, 1);
  opacity: 1;
  -webkit-overflow-scrolling: touch; }
  .detail-container.hidden {
    transform: translateY(100%); }
    .detail-container.hidden .detail__image {
      transform: translateY(50%); }

@media screen and (min-width: 768px) {
  .detail-container {
    height: 100%;
    flex-direction: row; } }

.detail-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  transition: all 1000ms cubic-bezier(0, 1.005, 0.22, 1); }

@media screen and (min-width: 768px) {
  .detail-wrapper {
    position: absolute;
    width: 38%;
    right: 0; } }

.detail-header {
  padding: calc(6vw + 20px) 6vw 3vw; }
  .detail-header .data {
    margin-bottom: 0; }

@media screen and (min-width: 768px) {
  .detail-header {
    padding: 3vw 3vw 1.5vw; } }

.detail-scroll {
  display: flex;
  flex-direction: column;
  padding: 3vw 0 0; }

@media screen and (min-width: 768px) {
  .detail-scroll {
    height: 100%;
    padding: 1.5vw 0 0; } }

.detail {
  display: flex;
  flex: 1;
  min-height: min-content;
  flex-direction: column;
  width: 100%;
  padding: 0 6vw 6vw;
  transition: all 1000ms cubic-bezier(0, 1.005, 0.22, 1); }
  .detail.detail--relations {
    display: block;
    padding: 6vw;
    flex: 0;
    background: rgba(0, 0, 0, 0.6); }
  .detail h1, .detail h2, .detail h3, .detail h4, .detail h5, .detail h6 {
    color: black; }

@media screen and (min-width: 768px) {
  .detail {
    padding: 0 3vw 3vw; }
    .detail.detail--relations {
      padding: 3vw; } }

.detail__title {
  padding-top: 2vw; }

@media screen and (min-width: 768px) {
  .detail__title {
    padding-top: 0; } }

.detail__relation {
  display: inline-block;
  margin: 0 1vw 1vw 0; }
  .detail__relation a {
    display: block;
    padding: 3vw;
    transition: all 500ms cubic-bezier(0, 1.005, 0.22, 1); }
    .detail__relation a:hover {
      background: white; }

@media screen and (min-width: 768px) {
  .detail__relation a {
    padding: 1vw; } }

.detail__archives {
  margin-top: 6vh;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between; }

.detail__archive-item {
  width: 48%;
  margin-bottom: 3vh;
  background: rgba(0, 0, 0, 0.6);
  transition: all 500ms cubic-bezier(0, 1.005, 0.22, 1); }
  .detail__archive-item h6, .detail__archive-item p {
    transition: all 500ms cubic-bezier(0, 1.005, 0.22, 1); }
  .detail__archive-item:hover {
    cursor: pointer;
    background: white; }
    .detail__archive-item:hover h6, .detail__archive-item:hover p {
      color: black; }
  .detail__archive-item .data {
    margin-bottom: 3vw; }
  .detail__archive-item p {
    margin-bottom: 0; }
  .detail__archive-item h1, .detail__archive-item h2, .detail__archive-item h3, .detail__archive-item h4, .detail__archive-item h5, .detail__archive-item h6 {
    color: white; }

@media screen and (min-width: 768px) {
  .detail__archive-item .data {
    margin-bottom: .5vw; } }

.detail__archive-image {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100px;
  margin-bottom: 1vw;
  font-size: 32px;
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat; }
  .detail__archive-image .glyph {
    position: absolute;
    overflow: visible; }

@media screen and (min-width: 768px) {
  .detail__archive-image {
    height: 150px;
    margin-bottom: 0; } }

.detail__archive-text {
  padding: 3vw; }

@media screen and (min-width: 768px) {
  .detail__archive-text {
    padding: 1vw; } }

.detail__audio {
  position: absolute;
  top: 3vw;
  right: calc(3vw + 32px);
  cursor: pointer; }
  .detail__audio .glyph {
    color: black;
    font-size: 32px; }

@media screen and (min-width: 768px) {
  .detail__audio {
    top: 3vw;
    right: calc(3vw + 32px); } }

.detail__close {
  position: absolute;
  top: 3vw;
  right: 3vw;
  cursor: pointer; }
  .detail__close .glyph {
    color: black;
    font-size: 32px; }

@media screen and (min-width: 768px) {
  .detail__close {
    top: 3vw;
    right: 3vw; } }

.detail__inline-image {
  position: relative;
  width: 100%;
  height: 200px;
  margin-bottom: 16px;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat; }
  .detail__inline-image .detail__image-copyright {
    text-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    position: absolute;
    bottom: 4px;
    left: 4px; }

@media screen and (min-width: 768px) {
  .detail__inline-image {
    display: none; } }

.detail__image {
  width: 62%;
  height: 100%;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  transform: translateY(0);
  transition: all 2000ms cubic-bezier(0, 1.005, 0.22, 1);
  background-color: black;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat; }
  .detail__image .detail__image-copyright {
    text-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    position: absolute;
    bottom: 8px;
    left: 8px; }

@media screen and (min-width: 768px) {
  .detail__image {
    display: block; } }

.detail__notifications {
  margin-top: 3vh; }

.mediaplayer-container {
  display: flex;
  position: fixed;
  width: 100%;
  height: 100%;
  padding: 12vh 6vw;
  background-color: rgba(0, 0, 0, 0.7); }
  .mediaplayer-container.hidden {
    display: none; }

@media screen and (min-width: 768px) {
  .mediaplayer-container {
    padding: 6vw; } }

.mediaplayer {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background: black;
  box-shadow: 0 0 100px rgba(0, 0, 0, 0.5); }

.player-canvas {
  height: 100%; }
  .player-canvas .NPOPlayer {
    height: 100%; }
    .player-canvas .NPOPlayer .jwplayer {
      height: 100% !important; }

.mediaplayer__title {
  position: absolute;
  top: calc(6vh + 4px);
  left: 6vw; }

@media screen and (min-width: 768px) {
  .mediaplayer__title {
    top: 3vw; } }

.mediaplayer__close {
  position: absolute;
  top: 6vh;
  right: 3vw;
  z-index: 100; }
  .mediaplayer__close .glyph {
    color: white;
    font-size: 32px; }

@media screen and (min-width: 768px) {
  .mediaplayer-container {
    top: 3vw; } }

.player {
  display: none; }
  .player.visible {
    display: inline-block; }

.cookie-consent {
  display: none; }
  .cookie-consent.visible {
    display: inline-block; }
  .cookie-consent .npo_cc_label {
    color: #fff;
    background-color: #f00;
    padding: 2px 4px; }
  .cookie-consent .npo_cc_placeholder_link, .cookie-consent .npo_cc_settings_link {
    text-decoration: underline; }

.share-gui.overlay--fullscreen {
  top: 40px; }

.introduction-container {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  overflow: auto;
  background-color: black;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat; }
  .introduction-container.hidden {
    display: none; }

.introduction {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
  height: 81%;
  padding: 6vw;
  transition: all 1000ms cubic-bezier(0, 1.005, 0.22, 1); }

@media screen and (min-width: 768px) {
  .introduction {
    width: 38%;
    padding: 3vw; } }

.introduction h6 {
  margin-bottom: 3vh; }

@media screen and (min-width: 768px) {
  .introduction h6 {
    font-size: 20px;
    line-height: 20px; } }

.introduction .logo {
  width: 100px;
  height: 100px;
  min-height: 100px;
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/img/30jdd-logo.svg");
  margin-bottom: 3vh; }

@media screen and (min-width: 768px) {
  .introduction .logo {
    width: 150px;
    height: 150px; } }

.introduction p {
  align-self: flex-start; }

.introduction .button {
  padding: 16px 64px;
  width: auto;
  height: auto;
  font-family: "simplistic_sans", "SimplisticSans", Arial, Helvetica, Sans, FreeSans, "Nimbus Sans L", Garuda, sans-serif;
  font-size: 22px;
  line-height: 22px;
  color: white;
  background-color: #cc3366;
  transition: all 500ms cubic-bezier(0, 1.005, 0.22, 1); }
  .introduction .button:hover {
    background: white;
    padding: 24px 72px;
    color: black; }

.introduction-container .frequency {
  width: 100%;
  height: 19%; }

.notifications-container {
  position: relative;
  width: 100%;
  height: 68%;
  border-top: 1px solid rgba(255, 255, 255, 0.5);
  transition: all 500ms cubic-bezier(0, 1.005, 0.22, 1); }
  .notifications-container.notifications-container--collapsed {
    height: 38%; }

@media screen and (min-width: 768px) {
  .notifications-container {
    width: 38%;
    height: 100%;
    border-top: 0 solid transparent;
    border-left: 1px solid rgba(255, 255, 255, 0.3); }
    .notifications-container.notifications-container--collapsed {
      height: 100%; } }

.notifications__title {
  margin: 3vh 0; }

.notifications {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  height: 100%;
  padding: 8vw 6vw;
  transition: all 500ms cubic-bezier(0, 1.005, 0.22, 1);
  -webkit-overflow-scrolling: touch; }
  .notifications-container--collapsed .notifications {
    padding: 6vw; }

@media screen and (min-width: 768px) {
  .notifications {
    padding: 3vw; }
    .notifications-container--collapsed .notifications {
      padding: 3vw; } }

.collapse {
  position: absolute;
  top: -12px;
  width: 100%;
  height: 34px;
  text-align: center;
  color: white; }

@media screen and (min-width: 768px) {
  .collapse {
    display: none; } }

.collapse__lip {
  top: 7px;
  left: 50%;
  width: 24px;
  height: 24px;
  margin-left: -12px;
  cursor: pointer;
  border-top: 1px solid rgba(255, 255, 255, 0.5);
  border-right: 1px solid rgba(255, 255, 255, 0.5);
  opacity: 1;
  transform: rotate(-45deg);
  background: black;
  display: none; }
  .notifications-container--collapsed .collapse__lip {
    display: inline-block; }

.collapse__icon {
  position: absolute;
  left: 50%;
  margin-left: -18px;
  display: block;
  font-size: 32px;
  color: white !important;
  cursor: pointer; }

.collapse__icon--up {
  top: 2px;
  display: none !important; }
  .notifications-container--collapsed .collapse__icon--up {
    display: inline-block !important; }

.collapse__icon--down {
  top: 14px;
  display: inline-block !important; }
  .notifications-container--collapsed .collapse__icon--down {
    display: none !important; }

.notifications__year {
  width: 100%;
  margin: 0 0 3vh; }

@media screen and (min-width: 768px) {
  .notifications__year {
    margin: 0 0 6vh; } }

.notification {
  width: 100%;
  margin: 1vh 0 3vh; }
  .notification .notification__hover {
    transition: all 500ms cubic-bezier(0, 1.005, 0.22, 1);
    width: 0;
    margin-right: 0; }
  .notification .notification__text {
    width: calc(100% - 12px); }
  .notification:hover .notification__hover {
    width: 6px;
    margin-right: 6px; }
  .notification a {
    display: flex; }
  .notification h5 {
    margin-bottom: 0; }
  .notification p {
    margin-bottom: 0; }
  .notification h6 {
    font-size: 18px;
    line-height: 18px; }

.selector {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 100px;
  min-height: 100px; }

.selector .selector__title {
  margin-right: 1vw; }

.selector .selector__slider {
  position: relative;
  display: flex;
  align-items: flex-end;
  height: 100px;
  min-height: 100px;
  overflow: hidden; }

.selector .selector__years {
  display: flex;
  flex-direction: column;
  margin-bottom: 14px;
  cursor: grab;
  transition: all 500ms cubic-bezier(0, 1.005, 0.22, 1); }

.selector .selector__year {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 64px;
  font-size: 64px;
  line-height: 64px;
  opacity: .2;
  transform: translateZ(0);
  transition: all 1000ms cubic-bezier(0, 1.005, 0.22, 1); }
  .selector .selector__year.selected {
    opacity: 1; }

.selector .selector__buttons {
  display: flex;
  flex-direction: column;
  align-items: center; }

.selector .selector__button {
  display: flex;
  align-items: center;
  cursor: pointer;
  opacity: 1;
  transition: all 500ms cubic-bezier(0, 1.005, 0.22, 1); }
  .selector .selector__button.disabled {
    opacity: .2; }

.selector .selector__button .glyph {
  color: white;
  font-size: 32px;
  transform: rotate(90deg); }

.visualization-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  height: 62%;
  padding: 6vw;
  /*
  background-image: url('../img/background-01.jpg');
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  */ }

@media screen and (min-width: 768px) {
  .visualization-container {
    width: 62%;
    height: 100%;
    padding: 3vw; } }

.visualization {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 100%; }

.visualization .visualization__controls {
  position: absolute;
  right: 0;
  bottom: 0; }

.visualization .audio__controls {
  position: absolute;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center; }
  .visualization .audio__controls .audio__title {
    margin-right: .5vw;
    color: white; }

.visualization .legend-container {
  position: absolute;
  left: 0;
  bottom: 0;
  display: none; }
  .visualization .legend-container .legend {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 32px; }
    .visualization .legend-container .legend .legend__category {
      margin: 3px .5vw 0;
      font-size: .8em; }

@media screen and (min-width: 1072px) {
  .visualization .legend-container {
    display: inline-block; } }

.button {
  float: left;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  display: flex;
  cursor: pointer; }
  .button .glyph {
    color: white;
    font-size: 32px; }
  .button .button__icon {
    width: 16px;
    height: 16px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center; }
    .button .button__icon.button__icon--zoom-in {
      background-image: url("../assets/img/icon-plus.svg"); }
    .button .button__icon.button__icon--zoom-out {
      background-image: url("../assets/img/icon-min.svg"); }

.frequency {
  margin-top: 1vh;
  flex: 1;
  transition: all 500ms cubic-bezier(0, 1.005, 0.22, 1); }

.frequency .frequency-bars {
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  height: 100%;
  transition: all 500ms cubic-bezier(0, 1.005, 0.22, 1); }

.frequency .frequency-bar {
  position: absolute;
  bottom: 0;
  left: 0;
  transition: all 500ms cubic-bezier(0, 1.005, 0.22, 1); }
  .frequency .frequency-bar.frequency-bar--enabled:hover, .frequency .frequency-bar.simulate-hovered {
    fill: white; }

.frequency .frequency-name {
  display: block;
  transform: rotate(90deg);
  font-size: 1em; }
  .frequency .frequency-name:hover, .frequency .frequency-name.simulate-hovered {
    fill: white; }

.frequency.frequency--saturated-full {
  flex: 0; }

.frequency.frequency--saturated-half .frequency-name {
  font-size: .6em; }

.frequency.frequency--saturated-full .frequency-name {
  font-size: .7em; }

@media screen and (min-width: 768px) {
  .frequency .frequency-name {
    font-size: 1em; }
  .frequency.frequency--saturated-full {
    flex: 1; }
  .frequency.frequency--saturated-half .frequency-name {
    font-size: .9em; }
  .frequency.frequency--saturated-full .frequency-name {
    font-size: .5em; } }

@media screen and (min-width: 1024px) {
  .frequency.frequency--saturated-full .frequency-name {
    font-size: .8em; } }

svg {
  width: 100%; }
